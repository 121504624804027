body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root,.App {
  height: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.rc-slider-disabled {
  background-color: transparent;
}

.patient__table.worklist--heightleft {
  height: calc(100vh - 57px);
}

.test__monitoring--btns {
  padding-top: 15px;
  text-align: right;
}
.test__monitoring--btns .test__monitoring--lists {
  display: inline-flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.test__monitoring--btns .test__monitoring--lists li {
  padding: 0 10px;
}
.test__monitoring--btns .test__monitoring--lists li .btn {
  min-width: 115px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.patient__view--list .patient__view--top {
  position: relative;
}
.patient__view--list .patient__view--timer {
  padding-right: 25px;
}
.device__delete--btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.device__delete--btn .btn {
  padding: 5px 4px;
  font-size: 0.875rem;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.app_version{
  font-size: 0.694444rem;
  background-color: rgba(23,25,35,0.7);
  position: fixed;
  bottom: 0.444444rem;
  right: 0.444444rem;
  padding: 0.296296rem;
  z-index: 1000;
  border-radius: 0.296296rem;
  margin: 0;
}
