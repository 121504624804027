
.player-wrapper {
    position: sticky;
    padding-top: 50% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player1 {
    position: absolute;
    top: 0;
    left: 0;
  }

  .react-player2 {
    position: absolute;
    top: 0;
    right: 0;    
  }